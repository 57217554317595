import { LoadScreen, useAppContext } from "@procision-software/ui";
import { useRouter } from "next/router";

export default function HomePage() {
  const context = useAppContext();
  const router = useRouter();

  if (!context.facility) {
    // redirect to org chooser
    void router.push("/sign-in/organization-chooser");
  }

  if (!context.practice && context.perspective === "practice") {
    // redirect to org  chooser
    void router.push("/sign-in/organizational-unit-chooser");
  }

  const { redirectUrl } = router.query as { redirectUrl?: string };
  const roles = context.roles;

  if (redirectUrl) {
    void router.push(redirectUrl);
  } else if (roles.includes("platform:admin")) {
    void router.push("/platform/admin/dashboard");
  } else if (roles.includes("asc")) {
    // The dashboard needs to be rendered first so that the clerk context is correctly updated
    // prior to redirecting the user. If this is not done, the calendar page may try to load the
    // wrong context before clerk updates, error out, and not retry once clerk updates.
    void router.push("/facility/dashboard");
  } else if (roles.includes("practice")) {
    void router.push("/practice/cases");
  }

  return <LoadScreen />;
}
